export default {
    form: () => {
      return {
        name: "Krijo Agjent Shitjesh",
        submit: {
          label: "Krijo",
          action: "submitSalesAgent",
        },
        fields: [
          [
            {
              value: "",
              name: "code_as_agent",
              label: "Kodi si agjent",
              type: "text-field",
              style: "flex-grow: 0.3",
              required: true,
              required_text: "Kjo fushe eshte e detyrueshme",
              unique: {
                model: "salesagents",
                field: "code_as_agent",
                where: [],
              },
              left_icon: "fa-solid fa-hashtag",
            },
            {
                value: "",
                name: "code_as_client",
                label: "Kodi si klient",
                type: "text-field",
                style: "flex-grow: 0.3",
                required: true,
                required_text: "Kjo fushe eshte e detyrueshme",
                unique: {
                  model: "salesagents",
                  field: "code_as_client",
                  where: [],
                },
                left_icon: "fa-solid fa-hashtag",
              }
          ],
          [
            {
                value: "",
                name: "master_number",
                label: "Nr. amzes",
                type: "text-field",
                style: "flex-grow: 0.3",
                required: true,
                required_text: "Kjo fushe eshte e detyrueshme",
                unique: {
                  model: "salesagents",
                  field: "master_number",
                  where: [],
                },
                left_icon: "fa-solid fa-hashtag",
              },
              {
                value: "",
                name: "personal_number",
                label: "Numri personal",
                type: "text-field",
                style: "flex-grow: 0.3",
                required: true,
                required_text: "Kjo fushe eshte e detyrueshme",
                unique: {
                  model: "salesagents",
                  field: "personal_number",
                  where: [],
                },
                left_icon: "fa-solid fa-hashtag",
              }
          ],
          [
            {
                value: '', 
                name: 'firstname', 
                label: 'Emri', 
                type: 'text-field', 
                style: 'flex-grow: 0.4;',
                required: true,
                required_text: 'Kjo fushe eshte e detyrueshme'
            },
            {
                value: '', 
                name: 'lastname', 
                label: 'Mbiemri', 
                type: 'text-field', 
                style: 'flex-grow: 0.4;',
                required: true,
                required_text: 'Kjo fushe eshte e detyrueshme'
            },
          ],
          [
            {
                value: '', 
                name: 'department', 
                label: 'Departamenti', 
                type: 'text-field', 
                style: 'flex-grow: 0.3;',
                required: true,
                required_text: 'Kjo fushe eshte e detyrueshme'
            },
            {
                value: '', 
                name: 'sector', 
                label: 'Sektori', 
                type: 'text-field', 
                style: 'flex-grow: 0.3;',
                required: true,
                required_text: 'Kjo fushe eshte e detyrueshme'
            },
            {
                value: '', 
                name: 'job_position', 
                label: 'Pozicion Pune', 
                type: 'text-field', 
                style: 'flex-grow: 0.3;',
                required: true,
                required_text: 'Kjo fushe eshte e detyrueshme'
            },
          ],
          [
            {
                value: '', 
                name: 'agent_profile', 
                label: 'Profili si agjent', 
                type: 'text-field', 
                style: 'flex-grow: 0.3;',
                required: true,
                required_text: 'Kjo fushe eshte e detyrueshme'
            },
            {
                value: '', 
                name: 'username', 
                label: 'Username', 
                type: 'text-field', 
                style: 'flex-grow: 0.3;',
                required: true,
                required_text: 'Kjo fushe eshte e detyrueshme'
            },
            {
                value: '', 
                name: 'email', 
                label: 'Email', 
                type: 'email-field', 
                style: 'flex-grow: 0.3;',
                required: true,
                required_text: 'Kjo fushe eshte e detyrueshme'
            },
          ]
        ],
      };
    },
  };
  
  
import Api from "@/services/Api";

export default {
    async getSalesAgents() {
        var agents = []
        await Api(true).post("sales_agents/list", {})
        .then(r => {
            agents = r.data.map(e => {
                e.id = e.guid
                e.label = e.agent_name
                return e
            })
        })
        return agents
    },

    async createSalesAgent(agent) {
        await Api(true).post("sales_agents/create", agent)
        .then(r => {
            agent = r.data
        })
        return agent
    },

    async editSalesAgent(agent) {
        await Api(true).post("sales_agents/edit", agent)
        .then(r => {
            agent = r.data
        })
        return agent
    }
}
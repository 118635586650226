<template>
    <div>
        <div class="columns">
            <div class="column">
                <div class="level p-0 m-0">
                <div class="level-left">
                    <div class="level-item">Agjentet e shitjeve</div>
                    <input class="input" style="margin: 5px; width: 300px" type="text" placeholder="Kerko..." v-model="global_search_filter" @input="onFilterTextBoxChanged">
                </div>
                <div class="level-right">
                    <div class="level">
                    <!-- <a v-if="am_allowed('transactions.can_create_warehouses')" @click.prevent="create_warehouse"> -->
                        <a href="" @click.prevent="create_agent">
                            <i class="fa fa-plus"></i>
                        </a>
                    <!-- </a> -->
                    </div>
                </div>
                </div>
                <ag-grid-vue
                style="width: 100%; height: 200px"
                class="ag-theme-balham"
                :columnDefs="columnDefs"
                :rowData="rowData"
                :rowSelection="rowSelection"
                :context="context"
                :localeText="localeText"
                :animateRows="true"
                @grid-ready="onGridReady"
                >
                </ag-grid-vue>
            </div>
            <div class="column is-5" v-if="form_edit || form_create">
                <DaForm v-if="form_create" @submitted="submitSalesAgent" :form="form_create"></DaForm> 
                <DaForm v-if="form_edit" @submitted="submitSalesAgent" :form="form_edit"></DaForm> 
            </div>
        </div>
    </div>
</template>

<script>
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-balham.css";
import { AgGridVue } from "ag-grid-vue3";
import DaForm from "@/components/daform/index.vue";
import ButtonsCell from "@/components/buttonscell/ButtonsCell.vue";
import createSalesAgent from "./forms/create";
import editSalesAgent from "./forms/edit";
import locale from "@/components/ag-grid/locale/al/al.js"
import SalesAgentsServices from "@/services/SalesAgents";
import permissionMixin from '@/mixins/permissions-mixin.js'
export default {
mixins: [permissionMixin],
components: {
    AgGridVue,
    DaForm,
},
beforeMount() {
    this.context = { componentParent: this };
  },
data() {
    return {
        global_search_filter: "",
        context: null,
        selectedAgent: {},
        departments: [],
        form_create: null,
        form_edit: null,
        columnDefs: [
            { headerName: "Kodi si agjent", field: "code_as_agent", filter: true, floatingFilter: true, sortable: true },
            { headerName: "Kodi si klient", field: "code_as_client", filter: true, floatingFilter: true, sortable: true },
            { headerName: "Nr. amzes", field: "master_number", filter: true, floatingFilter: true, sortable: true },
            { headerName: "Numri personal", field: "personal_number", filter: true, floatingFilter: true, sortable: true },
            { headerName: "Emri", field: "firstname", filter: true, floatingFilter: true, sortable: true },
            { headerName: "Mbiemri", field: "lastname", filter: true, floatingFilter: true, sortable: true },
            { headerName: "Departamenti", field: "department", filter: true, floatingFilter: true, sortable: true },
            { headerName: "Sektori", field: "sector", filter: true, floatingFilter: true, sortable: true },
            { headerName: "Pozicion Pune", field: "job_position", filter: true, floatingFilter: true, sortable: true },
            { headerName: "Profili si agjent", field: "agent_profile", filter: true, floatingFilter: true, sortable: true },
            { headerName: "Username", field: "username", filter: true, floatingFilter: true, sortable: true },
            { headerName: "Email", field: "email", filter: true, floatingFilter: true, sortable: true },
            {
                headerName         : "Action",
                cellRenderer       : ButtonsCell,
                cellRendererParams : this.context,
            }
        ],
        rowData: [],
        gridApi: null,
        defaultColDef: {
            flex: 1,
            minWidth: 100
        },
        rowSelection: "single",
        localeText: null
    }
},

methods: {
    onFilterTextBoxChanged() {
      this.gridApi.setQuickFilter(this.global_search_filter)
    },
    async open(mode, agent) {
        if(mode == "1") {
            this.form_create = null
            if(agent) {
                this.form_edit = null
                setTimeout(() => {
                    let form = editSalesAgent.form(
                        agent
                    )

                    this.form_edit = form
                    this.selectedAgent = agent
                })
            }
        }
    },
    onGridReady(params) {
        this.gridApi = params.api
        this.gridColumnApi = params.columnApi
    },
    create_agent() {
        this.form_edit = null
        this.form_create = createSalesAgent.form()
    },
    async submitSalesAgent(sa) {
        var notify_text = ""
        if(this.form_create != null) {
            this.rowData.push(await SalesAgentsServices.createSalesAgent(sa))
            notify_text = "Agjenti u krijua me sukses!"
            this.form_create = null
            this.$root.selected_one = {}
        }
        else {
            sa.guid = this.selectedAgent.guid
            let agent = await SalesAgentsServices.editSalesAgent(sa)
            this.rowData = [...this.rowData.map(wa => {
                if(wa.guid == agent.guid) wa = {...agent}
                return wa
            })]
            notify_text = "Agjenti u modifikua me sukses!"
            this.form_edit = null
            this.$root.selected_one = {}
        }
        this.$toast.success(notify_text)
    }
},

async created() {
    this.localeText = locale.locale_al()
    this.rowData = await SalesAgentsServices.getSalesAgents()
}
    
}
</script>

<style lang="scss" scoped>

</style>
export default{
	form: (sa) =>{
		return {
			name: 'Modifiko agjent',
			submit: {
				label: 'Modifiko',
				action: 'submitSalesAgent'
			},
			fields: [
				[
					{
						value: sa.code_as_agent, 
						name: 'code_as_agent', 
						label: 'Kodi si agjent', 
						type: 'text-field', 
						style: "flex-grow: 0.3",
						required: true,
						required_text: 'Kjo fushe eshte e detyrueshme',
						unique: {
							model: 'salesagents', 
							field: 'code_as_agent',
							where: [
								{
									column: 'guid',
									condition: '!=',
									value: sa.guid 
								}
							]
						}, 
						left_icon: 'fa-solid fa-hashtag', 
					},
					{
						value: sa.code_as_client, 
						name: 'code_as_client', 
						label: 'Kodi si klient', 
						type: 'text-field', 
						style: "flex-grow: 0.3",
						required: true,
						required_text: 'Kjo fushe eshte e detyrueshme',
						unique: {
							model: 'salesagents', 
							field: 'code_as_client',
							where: [
								{
									column: 'guid',
									condition: '!=',
									value: sa.guid 
								}
							]
						}, 
						left_icon: 'fa-solid fa-hashtag', 
					},
				],
				[
					{
						value: sa.master_number, 
						name: 'master_number', 
						label: 'Nr. amzes', 
						type: 'text-field', 
						style: "flex-grow: 0.3",
						required: true,
						required_text: 'Kjo fushe eshte e detyrueshme',
						unique: {
							model: 'salesagents', 
							field: 'master_number',
							where: [
								{
									column: 'guid',
									condition: '!=',
									value: sa.guid 
								}
							]
						}, 
						left_icon: 'fa-solid fa-hashtag', 
					},
					{
						value: sa.personal_number, 
						name: 'personal_number', 
						label: 'Numri personal', 
						type: 'text-field', 
						style: "flex-grow: 0.3",
						required: true,
						required_text: 'Kjo fushe eshte e detyrueshme',
						unique: {
							model: 'salesagents', 
							field: 'personal_number',
							where: [
								{
									column: 'guid',
									condition: '!=',
									value: sa.guid 
								}
							]
						}, 
						left_icon: 'fa-solid fa-hashtag', 
					},
				],
				[
					{
						value: sa.firstname,
						name: 'firstname',
						label: 'Emri',
						type: 'text-field',
						style: "flex-grow: 0.4",
						required: true,
						required_text: 'Kjo fushe eshte e detyrueshme'
					},
					{
						value: sa.lastname,
						name: 'lastname',
						label: 'Mbiemri',
						type: 'text-field',
						style: "flex-grow: 0.4",
						required: true,
						required_text: 'Kjo fushe eshte e detyrueshme'
					},
				],
				[
					{
                        value: sa.department, 
                        name: 'department', 
                        label: 'Departamenti', 
                        type: 'text-field', 
                        style: 'flex-grow: 0.3;',
                        required: true,
                        required_text: 'Kjo fushe eshte e detyrueshme'
                    },
					{
						value: sa.sector,
						name: 'sector',
						label: 'Sektori',
						type: 'text-field',
						style: 'flex-grow: 0.3',
						required: true,
						required_text: 'Kjo fushe eshte e detyrueshme'
					},
					{
						value: sa.job_position,
						name: 'job_position',
						label: 'Pozicion Pune',
						type: 'text-field',
						style: 'flex-grow: 0.3',
						required: true,
						required_text: 'Kjo fushe eshte e detyrueshme'
					},
				],
				[
					{
						value: sa.agent_profile,
						name: 'agent_profile',
						label: 'Profili si agjent',
						type: 'text-field',
						style: 'flex-grow: 0.3',
						required: true,
						required_text: 'Kjo fushe eshte e detyrueshme'
					},
					{
						value: sa.username,
						name: 'username',
						label: 'Username',
						type: 'text-field',
						style: 'flex-grow: 0.3',
						required: true,
						required_text: 'Kjo fushe eshte e detyrueshme'
					},
					{
						value: sa.email,
						name: 'email',
						label: 'Email',
						type: 'text-field',
						style: 'flex-grow: 0.3',
						required: true,
						required_text: 'Kjo fushe eshte e detyrueshme'
					},
				]
			]
		}
	}
}
